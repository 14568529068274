import { Theme } from './theme.interface';

export const Login: Theme = {
    name: 'login',
    properties: {
      '--foreground-default': '#08090A',
      '--foreground-secondary': 'black',
      '--foreground-tertiary': 'black',
      '--foreground-quaternary': 'black',

      '--background-default': 'url(\'/assets/background/HQBackground.png\')',
      '--background-secondary': 'white',
      '--background-tertiary': 'white',
      '--background-quaternary': 'white',

      '--button-primary-background': '#3F51B5',
      '--button-primary-foreground': 'white',
      '--button-primary-background-disabled': '#7983B8',
      '--button-primary-foreground-disabled': 'white',
      '--button-secondary-background': 'white',
      '--button-secondary-foreground': '#3E50B3',
      '--button-secondary-background-disabled': '#7983B8',
      '--button-secondary-foreground-disabled': 'white',
      '--button-accent-background': '#e83e8c',
      '--button-accent-foreground': 'white',
      '--button-danger-background': 'red',
      '--button-danger-foreground': 'white',

      '--shadow': '0 1px 3px 0 rgba(92, 125, 153, 0.5)',
      '--error-icon': 'red',

      '--or-background': 'white',
      '--or-foreground': 'black',
      '--spinner': 'invert(25%) sepia(83%) saturate(1319%) hue-rotate(213deg) brightness(96%) contrast(88%)',
      '--loading-background': 'white',
      '--quill-toolbar-svg': 'invert(99%) sepia(31%) saturate(425%) hue-rotate(247deg) brightness(109%) contrast(80%);',
      '--chart-background': 'white',
      '--progress-color': '#3F51B5',
      '--dialog-background': 'white',
      '--mca-background': 'white',
      '--mca-foreground': 'black',

      '--preview-one': 'white',
	  '--preview-two': '#C0E2FF',
	  '--logo-dark-mode-dark-bg': 'brightness(1) invert(0)',

    },
    canSelect: false
};
